<template>
    <div class="password_modal">
        <h4>Are you sure?</h4>
        <div class="password_modal__note">
            You need to verify your account password for security reasons
        </div>
        <form-input
            v-model="password"
            name="password"
            legend="Password"
            prepend-icon="LockIcon"
            :errors="errorText || null"
            :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
            :type="showPassword ? 'text' : 'password'"
            @clicked="showPassword = !showPassword"
        />
    </div>
</template>

<script>
export default {
    name: 'PasswordTest',
    props: {
        value: {
            type: String,
            default: ''
        },
        errorText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showPassword: false
        }
    },
    computed: {
        password: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.password_modal{
    font-size: 16px;
    line-height: 140%;
    color: #1F2939;
    h4{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #1F2939;
        text-align: left;
        margin-bottom: 20px;
    }
    &__note{
        background: $round-count;
        border-radius: 4px;
        margin-bottom: 40px;
     }
}
</style>
