<template>
    <profile-layout>
        <template #header>
            <div
                class="arrowWrap"
                @click="toProfile"
            >
                <arrow />
            </div>
            Change Password
        </template>
        <template #content>
            <div class="input-field">
                <form-input
                    v-model="password"
                    name="password"
                    legend="Password"
                    prepend-icon="LockIcon"
                    :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                    :type="showPassword ? 'text' : 'password'"
                    :errors="errorText || null"
                    tabindex="1"
                    @clicked="showPassword = !showPassword"
                    @input="testPassword"
                />
                <progress-linear
                    v-if="password.length > 7"
                    :style="{marginBottom: '30px'}"
                    :validate="passwordValidation"
                />
            </div>
            <div class="input-field">
                <form-input
                    v-model="passwordConfirmation"
                    name="confirm-password"
                    legend="Confirm password"
                    prepend-icon="LockIcon"
                    :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                    :type="showPassword ? 'text' : 'password'"
                    tabindex="2"
                    @clicked="showPassword = !showPassword"
                />
            </div>
            <password-modal
                :name="'password-test-password'"
                :title="'Change Password'"
            />
        </template>
        <template #footer>
            <custom-button
                default
                class="button btn-base_colored"
                :type="'submit'"
                width="100%"
                tabindex="3"
                @on-btn-click="validationData"
            >
                Submit
            </custom-button>
        </template>
    </profile-layout>
</template>

<script>
import {
    ACTION_GET_CURRENT_USER
} from '@/store/modules/auth/action-types'
import { createNamespacedHelpers } from 'vuex'

import { changPassword } from '@/services/profileService';
import passwordValidationMixin from '@/mixins/passwordValidationMixin'
import ProfileLayout from '@/layouts/ProfileLayout'
import ProgressLinear from '@/components/ProgressLinear'
import PasswordModal from '@/components/modals/PasswordModal'
import Arrow from '@/icons/Arrow'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('auth')

export default {
    name: 'ChangePassword',
    components: {
        ProfileLayout, Arrow, ProgressLinear, PasswordModal
    },
    mixins: [passwordValidationMixin],
    data() {
        return {
            showPassword: false,
            password: '',
            passwordConfirmation: '',
            passwordValidation: { strength: 0 },
            errorText: ''
        }
    },
    activated() {
        document.querySelector('input')?.focus()
        this.$bus.$on('confirm', this.submit)
    },
    deactivated() {
        this.$bus.$off('confirm', this.submit)
    },
    methods: {
        ...mapAdminActions([
            ACTION_GET_CURRENT_USER
        ]),
        testPassword() {
            this.errorText = ''
            if (this.password.includes(' ')) {
                this.errorText = 'Password cannot include spaces'
            }
            this.validatePassword(this.password).then((result) => { this.passwordValidation = result })
        },
        toProfile() {
            this.$router.push({ name: 'user-profile' })
        },
        reset() {
            this.password = ''
            this.passwordConfirmation = ''
        },
        validationData() {
            this.errorText = ''
            if (this.passwordValidation.strength < 75) {
                this.errorText = 'Password is not strong enough'
                this.$bus.$emit('showSnackBar', 'Password is not strong enough', 'error')
                return
            }

            if (this.password !== this.passwordConfirmation) {
                this.errorText = 'Passwords not matched'
                this.$bus.$emit('showSnackBar', 'Passwords not matched', 'error')
                return
            }

            this.$bus.$emit('openModal', 'password-test-password')
        },
        async submit(name) {
            if (name !== 'password-test-password') { return }
            changPassword(this.password).then(async () => {
                await this[ACTION_GET_CURRENT_USER]()
                this.$bus.$emit('showSnackBar', 'Your password was changed', 'success')
                this.reset()
                this.toProfile()
            }).catch((e) => {
                this.$bus.$emit('showSnackBar', e.response?.data?.message || 'Invalid data', 'error')
            })
        }
    }
}
</script>

<style scoped>
.input-field {
    margin-bottom: 35px;
}
</style>
