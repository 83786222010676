<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="profile-wrap"
    >
        <div class="profile">
            <h2 class="profile__header">
                <slot name="header" />
            </h2>
            <div class="profile__body">
                <slot name="content" />
            </div>
            <div
                v-if="hasFooterSlot"
                class="profile__footer"
            >
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileLayout',
    data() {
        return {
            isShowedSidebar: true
        }
    },
    computed: {
        hasFooterSlot() {
            return !!this.$slots.footer
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    }
}
</script>

<style lang='scss' scoped>
    @import "@/assets/styles/dashboard/components/profile/profile-layout";
</style>
