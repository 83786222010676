<template>
    <modal-layout
        v-if="dialog"
        ref="password_modal"
        :size="'small'"
        :name="name"
        :hidden="hidden"
        :style="{ 'z-index': order }"
        @closeModal="hideModal(name)"
        @hideModal="hideModal(name)"
    >
        <template #header>
            <h2>{{ title }}</h2>
        </template>
        <template #content>
            <password-test
                v-model="currentPassword"
                :error-text="errorText || null"
            />
        </template>
        <template #buttons>
            <custom-button
                default
                @on-btn-click="submit"
            >
                Confirm
            </custom-button>
            <custom-button
                default
                @on-btn-click="onHide"
            >
                Cancel
            </custom-button>
        </template>
    </modal-layout>
</template>

<script>
import modalsEventBusMixins from '@/mixins/modals/modalsEventBusMixins'
import PasswordTest from '@/components/profile/PasswordTest'
import { checkPassword } from '@/services/profileService'

export default {
    name: 'PasswordModal',
    components: { PasswordTest },
    mixins: [modalsEventBusMixins],
    props: {
        title: String,
        name: String
    },
    data() {
        return {
            currentPassword: '',
            errorText: ''
        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                document.addEventListener('keypress', this.onKeyPress)
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.password_modal.$el.querySelector('input').focus()
                    }, 200)
                })
            } else {
                document.removeEventListener('keypress', this.onKeyPress)
            }
        }
    },
    methods: {
        onKeyPress(e) {
            if (e.charCode !== 13) { return }
            this.submit()
        },
        restData() {
            this.currentPassword = ''
            this.errorText = ''
        },
        async submit() {
            try {
                await checkPassword(this.currentPassword)
                this.restData()
                this.$emit('onConfirmClick')
                this.confirmModal(this.name)
            } catch (err) {
                this.errorText = err.response.data.message || 'Something went wrong.'
                this.$bus.$emit('showSnackBar', this.errorText, 'error')
            }
        },
        onHide() {
            this.restData()
            this.hideModal(this.name)
        }
    }
}
</script>

<style scoped>

</style>
